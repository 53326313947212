import { CSS } from "../../constants/css";
import { isVisible } from "../../utils/dom";

const instance = ($el: HTMLElement) => {
  const dom = {
    $menu: $el.querySelectorAll<HTMLElement>(".js-menu"),
    $toggleBtn: $el.querySelector<HTMLElement>(".js-menu-toggle"),
    $menuItems: $el.querySelectorAll<HTMLElement>(".js-menu-item"),
  };

  const setupListeners = () => {
    dom.$toggleBtn?.addEventListener("click", function () {
      toggle(this);
    });
  };

  const setupTabbability = () => {
    const burgerVisible = isVisible(dom.$toggleBtn); // mobile
    const burgerExpanded =
      dom.$toggleBtn?.getAttribute("aria-expanded") === "true";

    dom.$menuItems.forEach(($menuItem) => {
      if ((burgerVisible && burgerExpanded) || !burgerVisible) {
        $menuItem.removeAttribute("tabindex");
        return;
      }
      $menuItem.setAttribute("tabindex", "-1");
    });
  };

  const toggle = (el: HTMLElement) => {
    dom.$menu.forEach(($menu) => {
      $menu.classList.toggle(CSS.active);
    });
    dom.$toggleBtn?.classList.toggle(CSS.active);

    el.setAttribute(
      "aria-expanded",
      el.getAttribute("aria-expanded") === "true" ? "false" : "true",
    );
    setupTabbability();
  };

  setupListeners();
  setupTabbability();

  screen.orientation.addEventListener("change", setupTabbability);
  window.addEventListener("resize", setupTabbability);
};

export default () => {
  const $nav = document.querySelectorAll<HTMLElement>(".js-nav");

  $nav.forEach(($element) => {
    instance($element);
  });
};
